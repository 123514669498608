<template>
  <div>
    <category-save-sidebar
      ref="sidebar"
      @refresh="fetch"
    />
    <b-card
      no-body
      class="mb-0"
    >
      <div class="d-flex m-2 align-items-center justify-content-between">
        <h4 />
        <b-button
          variant="primary"
          @click="create()"
        >Add Category</b-button>
      </div>
      <b-table
        :items="categories"
        :fields="fields"
        responsive
        show-empty
        empty-text="No categories found"
        :busy="loading"
      >
        <template v-slot:cell(image)="data">
          <b-img-lazy
            v-if="data.item.category_image_url != null"
            :src="data.item.category_image_url"
            width="100"
            alt="item image"
          />
        </template>
        <template v-slot:cell(category_name)="data">
          <b-link
            @click="update(data.item)"
          >
            {{ data.item.category_name }}
          </b-link>
        </template>
        <template v-slot:cell(actions)="data">
          <b-button
            variant="danger"
            size="sm"
            class="ml-1"
            @click="confirmDelete(data.item.category_id)"
          >
            Delete
          </b-button>
        </template>
      </b-table>
    </b-card>
    <icon-modal
      ref="confirm"
      icon="XOctagonIcon"
      icon-classes="text-danger"
      @close="id = null"
    >
      <p class="text-center">
        Are you sure you would like to delete this category?
      </p>
      <template v-slot:footer>
        <b-button
          variant="default"
          @click="$refs.confirm.close()"
        >
          Go Back
        </b-button>
        <spinner-button
          variant="danger"
          :loading="deleting"
          @click="processDelete()"
        >
          Delete
        </spinner-button>
      </template>
    </icon-modal>
  </div>
</template>

<script>
import {
  BCard,
  BTable,
  BButton,
  BLink,
  BImgLazy,
} from 'bootstrap-vue'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import itemStoreService from '@/services/itemStoreService'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import CategorySaveSidebar from '@/views/categories/CategorySaveSidebar.vue'
import IconModal from '@/layouts/components/IconModal.vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BLink,
    BImgLazy,
    CategorySaveSidebar,
    IconModal,
    SpinnerButton,
  },
  setup() {
    // Store
    const ITEMS_STORE_MODULE = 'app-item'
    if (!store.hasModule(ITEMS_STORE_MODULE)) store.registerModule(ITEMS_STORE_MODULE, itemStoreService)

    onUnmounted(() => {
      if (store.hasModule(ITEMS_STORE_MODULE)) store.unregisterModule(ITEMS_STORE_MODULE)
    })

    // Constants
    const toast = useToast()

    // Refs
    const sidebar = ref(null)
    const confirm = ref(null)

    // Variables
    const loading = ref(false)
    const deleting = ref(false)
    const id = ref(null)
    const categories = ref([])
    const fields = [
      {
        key: 'category_id',
        label: 'ID',
      },
      {
        key: 'image',
        label: '',
      },
      {
        key: 'category_name',
        label: 'Name',
      },
      {
        key: 'Actions',
      },
    ]

    // Functions
    const fetch = () => {
      loading.value = true
      store.dispatch(`${ITEMS_STORE_MODULE}/fetchCategories`)
        .then(response => {
          categories.value = response.data
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong fetching categories please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          loading.value = false
        })
    }
    const create = () => {
      sidebar.value.open()
    }
    const update = category => {
      sidebar.value.update(category)
    }
    const confirmDelete = catId => {
      id.value = catId
      confirm.value.open()
    }
    const processDelete = () => {
      deleting.value = true
      store.dispatch(`${ITEMS_STORE_MODULE}/deleteCategory`, { id: id.value })
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully deleted the category',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          fetch()
          confirm.value.close()
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong deleting the category please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          deleting.value = false
        })
    }

    // Initial
    fetch()

    return {
      categories,
      fields,
      id,
      loading,
      deleting,

      sidebar,
      confirm,

      fetch,
      create,
      update,

      confirmDelete,
      processDelete,
    }
  },
}
</script>
