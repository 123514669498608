<template>
  <b-sidebar
    v-model="showSidebar"
    shadow
    backdrop
    no-header
    sidebar-class="sidebar-lg"
    bg-variant="white"
    right
    @hidden="resetForm()"
  >
    <b-card-body>
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit }"
      >
        <b-form
          @submit.prevent="handleSubmit(submit)"
        >
          <validation-provider
            #default="validationContext"
            name="category name"
            rules="required|min:2"
          >
            <b-form-group label="Category Name">
              <b-form-input
                v-model="category.category_name"
                :state="getValidationState(validationContext)"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <b-form-group label="Category Image">
            <b-form-file
              v-model="categoryImage"
              accept=".jpg,.jpeg,.png,.svg"
            />
          </b-form-group>

          <div class="d-flex mt-2">
            <spinner-button
              variant="primary"
              type="submit"
              class="mr-2"
              :loading="submitting"
            >
              Save
            </spinner-button>
            <b-button
              @click="close()"
            >
              Go Back
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </b-card-body>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BCardBody,
  BForm,
  BFormGroup,
  BFormInvalidFeedback,
  BFormInput,
  BFormFile,
  BButton,
} from 'bootstrap-vue'
import SpinnerButton from '@/layouts/components/SpinnerButton.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { ref } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { required, min } from '@core/utils/validations/validations'

export default {
  components: {
    BSidebar,
    BCardBody,
    BForm,
    BFormGroup,
    BFormInvalidFeedback,
    BFormInput,
    BFormFile,
    BButton,

    SpinnerButton,

    ValidationProvider,
    ValidationObserver,
  },
  data: () => ({
    required,
    min,
  }),
  setup(props, { emit }) {
    // Constants
    const ITEM_STORE_KEY = 'app-item'
    const toast = useToast()

    // Variables
    const blankCategory = {
      category_id: 0,
      category_name: null,
    }
    const category = ref(JSON.parse(JSON.stringify(blankCategory)))
    const categoryImage = ref(null)
    const submitting = ref(false)
    const showSidebar = ref(false)

    // Functions
    const open = () => {
      showSidebar.value = true
    }
    const close = () => {
      showSidebar.value = false
    }
    const reset = () => {
      category.value = JSON.parse(JSON.stringify(blankCategory))
      categoryImage.value = null
    }
    const submit = () => {
      submitting.value = true
      const formData = new FormData()
      formData.append('name', category.value.category_name)
      if (categoryImage.value != null) {
        formData.append('category_image', categoryImage.value)
      }

      const action = category.value.category_id === 0 ? `${ITEM_STORE_KEY}/createCategory` : `${ITEM_STORE_KEY}/updateCategory`
      const params = category.value.category_id === 0 ? { formData } : { id: category.value.category_id, formData }
      store.dispatch(action, params)
        .then(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Successfully saved the category',
              icon: 'CheckCircleIcon',
              variant: 'success',
            },
          })
          close()
          emit('refresh')
        }).catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: 'Something went wrong saving the category please try again later',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        }).finally(() => {
          submitting.value = false
        })
    }
    const update = cat => {
      category.value = JSON.parse(JSON.stringify(cat))
      showSidebar.value = true
    }

    // Validation
    const {
      resetForm, refFormObserver, getValidationState,
    } = formValidation(reset)

    return {
      category,
      categoryImage,
      submitting,
      showSidebar,

      open,
      close,
      submit,
      update,

      resetForm,
      refFormObserver,
      getValidationState,
    }
  },
}
</script>
